export const loaderPostFetch = async (url, data) => {
  //let fUrl=`${window.API}/${url}`;
  //let fUrl = `http://localhost:8200/bank/${url}`;
  let fUrl = `https://finedge-financo.online/${url}`;
  return new Promise(async (y, n) => {
    try {
      const response = await fetch(fUrl, {
        method: 'POST',
        body: data,
      });
      const movies = await response.json();
      y(movies);
      //console.log('Download complete', response);
    } catch (error) {
      //console.error(`Download error: ${error.message}`);
      y();
    }
  });
};

export const csAmount = (n) => {
  return new Intl.NumberFormat('en', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    //minimumSignificantDigits: 2,
  }).format(n);
};
